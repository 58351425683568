import { CriteriaProgressItemDto, CriterionDto } from '../../../openapi';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  showDoneCriteriaSelector,
  showRelevantCriteriaSelector,
} from '../../../features/criteria/redux/utils';
import { useTranslation } from '../../../hooks/useTranslation';
import { CriteriaCard } from '../criteria-card/criteria-card';
import { lessonStatusIsDone, normalizeArray } from '../../../shared';
import { useCurrentProjectNav } from '../../../hooks/projects/useCurrentProjectNav';
import { useCurrentUser } from '../../../hooks/redux/useCurrentUser';
import { CriteriaListLayout } from './criteria-list-layout';

import './criteria.scss';

type Props = {
  category: CriterionDto.category;
  criteria: CriterionDto[];
};

export const CriteriaList = ({ category, criteria }: Props) => {
  const { renderTranslation } = useTranslation();
  const { userCriteriaProgress } = useCurrentUser();
  const showRelevantCriteria = useAppSelector(showRelevantCriteriaSelector);
  const showDoneCriteria = useAppSelector(showDoneCriteriaSelector);

  const cardsToRender = useFilteredCriteriaByChosenSettings({
    criteria,
    criteriaProgress: userCriteriaProgress,
    showRelevant: showRelevantCriteria,
    showDone: showDoneCriteria,
    category,
  });

  if (!cardsToRender.length) {
    return (
      <CriteriaListLayout category={category}>
        <p>
          {renderTranslation({
            ru:
              'Критерии соответствующие выбранным фильтрам не найдены или данная категория пока недоступна.',
            uk:
              'Критерії відповідні обраним фільтрам не знайдено або ця категорія поки що недоступна.',
          })}
        </p>
      </CriteriaListLayout>
    );
  }

  const criteriaList = cardsToRender.map((criterion) => (
    <li className='criteria__item' key={criterion.id} tabIndex={1}>
      <CriteriaCard criterion={criterion} />
    </li>
  ));

  return (
    <CriteriaListLayout category={category}>
      <ul className='criteria__list'>{criteriaList}</ul>
    </CriteriaListLayout>
  );
};

type FilterCriteriaByChosenSettingsArgs = {
  criteria: CriterionDto[];
  criteriaProgress: CriteriaProgressItemDto[];
  category: CriterionDto.category;
  showDone: boolean;
  showRelevant: boolean;
};
function useFilteredCriteriaByChosenSettings({
  criteria,
  criteriaProgress,
  category,
  showDone,
  showRelevant,
}: FilterCriteriaByChosenSettingsArgs): CriterionDto[] {
  const normalizedCriteria = normalizeArray<CriterionDto>(criteria, 'id');
  const { currentLesson } = useCurrentProjectNav();

  let result = showRelevant
    ? filterCriteriaByCategory(currentLesson.criteria, category)
    : [...criteria];

  if (!showDone) {
    const criteriaProgressForCategory = criteriaProgress.filter((progressItem) =>
      Boolean(normalizedCriteria[progressItem.criterionId]),
    );

    const doneCriteriaIds = criteriaProgressForCategory
      .filter(
        (progressItem) =>
          lessonStatusIsDone(progressItem.status) &&
          normalizedCriteria[progressItem.criterionId].category === category,
      )
      .map((doneCriterion) => doneCriterion.criterionId);

    result = result.filter((criterion) => !doneCriteriaIds.includes(criterion.id));
  }

  return result.sort((current, next) => current.number - next.number);
}

function filterCriteriaByCategory(criteria: CriterionDto[], category: CriterionDto.category) {
  return [
    ...criteria
      .filter((criterion) => criterion.category === category)
      .sort((a, b) => a.number - b.number),
  ];
}
