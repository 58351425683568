import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import { RegisterDto } from '../../../openapi';

const baseUrl = 'auth/';

const registerEndpoint: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint(baseUrl + 'register'),
};

export const registerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<void, RegisterDto>({
      query: (body) => ({
        url: registerEndpoint.path,
        method: registerEndpoint.method,
        body,
      }),
    }),
  }),
});

export const { useRegisterMutation } = registerApi;
