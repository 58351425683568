import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  label: string;
  placeholder: string;
  name: string;
  type?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
};

export const FormGroup = ({ name, label, type = 'text', placeholder, inputProps }: Props) => {
  // Workaround to use FormContext with unknown context
  const form = useFormContext<any>();
  const fieldState = form.getFieldState(name);
  const isError = fieldState.error;

  return (
    <div className={`main-form__group ${isError ? 'main-form__group--error' : ''}`}>
      <label className={`main-form__label ${isError ? 'error-label' : ''}`} htmlFor={name}>
        {label}
        <span className={'error-tip'}>?</span>
        <span className='error-msg'>{fieldState.error?.message}</span>
      </label>
      <input
        id={name}
        className='main-form__input'
        placeholder={placeholder}
        onFocus={() => form.clearErrors(name)}
        type={type}
        required
        {...inputProps}
        {...form.register(name)}
      />
    </div>
  );
};
