import { CriteriaProgressItemDto } from '../../../openapi';
import { Spinner } from '../../spinner/Spinner';
import { useTranslation } from '../../../hooks/useTranslation';

const { TODO, DONE } = CriteriaProgressItemDto.status;

type Props = {
  value: boolean;
  onCheckboxChange: (value: CriteriaProgressItemDto.status) => void;
  isLoading: boolean;
};

export const Checkbox = ({ value, onCheckboxChange, isLoading }: Props) => {
  const { renderTranslation } = useTranslation();

  const onClickHandler = () => {
    if (isLoading) {
      return;
    }

    const newCheckedValue = !value;

    onCheckboxChange(newCheckedValue ? DONE : TODO);
  };

  return (
    <div className={'criteria-card__status'} onClick={onClickHandler}>
      <label className={'main-checkbox'}>
        <span className='sr-only'>{renderTranslation(value ? checkedText : uncheckedText)}</span>
        <input
          className={'main-checkbox__checkbox'}
          type={'checkbox'}
          checked={isLoading ? false : value}
          readOnly
        />
        <span className={'main-checkbox__label'}> </span>
      </label>
      {isLoading && <Spinner />}
    </div>
  );
};

const checkedText = { ru: 'Выполнено', uk: 'Виконано' };
const uncheckedText = { ru: 'Отметить как выполненое', uk: 'Позначити як виконане' };
