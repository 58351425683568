/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DesignAssetsEntity } from './DesignAssetsEntity';
import type { ProjectLessonDto } from './ProjectLessonDto';
import type { TagDto } from './TagDto';
import type { Translation } from './Translation';

export type ProjectDto = {
    id: string;
    title: string;
    name: Translation;
    designPreviewImagesUrls: Array<string>;
    designAssets: Array<DesignAssetsEntity>;
    accessType: ProjectDto.accessType;
    difficultyLevel: number;
    tags: Array<TagDto>;
    description: Translation;
    assetsProvided: Translation;
    projectAssets?: string;
    instructions: Translation;
    projectImageUrl: string;
    lessons: Array<ProjectLessonDto>;
    color: string;
}

export namespace ProjectDto {

    export enum accessType {
        GUEST = 'guest',
        FREE = 'free',
        PRO = 'pro',
        PRO_PLUS = 'proPlus',
        EXPERT = 'expert',
        UNLIMITED = 'unlimited',
    }


}