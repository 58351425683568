import React, { useEffect } from 'react';
import { notifications } from '@mantine/notifications';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  getAuthData,
  isApiValidationError,
  isUnauthorizedResponse,
  setAuthData,
} from '../../shared';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from '../../hooks/useTranslation';
import { Spinner } from '../../components/spinner/Spinner';
import { LoginDto } from '../../openapi';
import { FormGroup } from '../../components/forms/form-group';
import { setToken } from '../user/redux/user-slice';
import { useLoginMutation } from './redux/auth-api';
import { authFormValuesSchema, FormValues } from './types';

export const AuthFeature = () => {
  const dispatch = useAppDispatch();
  const { wasLoggedIn } = useAuth();
  const [login, { isLoading }] = useLoginMutation();
  const { renderTranslation } = useTranslation();
  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(authFormValuesSchema),
  });

  useEffect(() => {
    const authData = getAuthData();
    if (!authData || wasLoggedIn) {
      return;
    }
  }, []);

  async function onSubmit(data: FormValues) {
    const loginDto: LoginDto = {
      password: data.password,
      username: data.email,
    };
    const response = await login(loginDto)
      .unwrap()
      .catch((error) => {
        if (isUnauthorizedResponse(error)) {
          notifications.show({
            message: 'Невірний пароль або email!',
            title: 'Помилка авторізації',
            color: 'red',
          });
          return;
        }

        if (!isApiValidationError(error)) {
          notifications.show({
            message: "Сталася помилка, будь ласка, зв'яжіться з адміністратором платформи",
            title: 'Щось пішло не так',
            color: 'orange',
          });
          return;
        }

        const errorFields = error.data.map(
          ({ targetField }) => targetField,
        ) as (keyof FormValues)[];
        errorFields.forEach((field) => {
          form.setError(field, { type: 'custom' });
        });
      });

    if (!response) {
      return;
    }

    form.reset();
    setAuthData({ token: response.token });
    dispatch(setToken(response.token));
  }

  return (
    <FormProvider {...form}>
      <form className='main-form' onSubmit={form.handleSubmit(onSubmit)}>
        {!isLoading ? (
          <>
            <FormGroup
              label={renderTranslation({ ru: 'Ваш email', uk: 'Ваш email' })}
              placeholder={renderTranslation({ ru: 'Введите email', uk: 'Введіть email' })}
              name={'email'}
              type={'email'}
            />
            <FormGroup
              label={'Пароль'}
              placeholder={renderTranslation({ ru: 'Введите пароль', uk: 'Введіть пароль' })}
              name={'password'}
              type={'password'}
            />
            <button className='btn btn--green btn--lg' type='submit'>
              {renderTranslation({ ru: 'Войти', uk: 'Увійти' })}
            </button>
          </>
        ) : (
          <Spinner />
        )}
      </form>
    </FormProvider>
  );
};
