import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AlertModal } from '../../components/modal';
import { isApiValidationError, NavigationRoutes } from '../../shared';
import { useTranslation } from '../../hooks/useTranslation';
import { useGetMentorsSummaryQuery } from '../user/redux/user-api';
import { useGetAllStudyClassesQuery } from '../study-class/redux/study-class-api';
import { MentorSummary, RegisterDto } from '../../openapi';
import { Spinner } from '../../components/spinner/Spinner';
import { FormGroup } from '../../components/forms/form-group';
import { useRegisterMutation } from './redux/register-api';
import {
  filterMentorsByStudyClass,
  mapMentorsToSelectArray,
  mapStudyClassesToSelectArray,
} from './helpers';
import { FormValues, schema } from './types';
import { SelectInput } from './components/select-input';

export const RegisterFeature = () => {
  const { renderTranslation, currentLanguage } = useTranslation();
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const { data: allMentors = [], isFetching: mentorsLoading } = useGetMentorsSummaryQuery();
  const { data: studyClasses = [], isFetching: studyClassLoading } = useGetAllStudyClassesQuery();
  const form = useForm<FormValues>({
    defaultValues: formDefaultValues,
    resolver: zodResolver(schema),
    mode: 'onChange',
  });
  const { formState } = form;

  const [showModal, setShowModal] = useState(false);
  const [canSelectMentor, setCanSelectMentor] = useState(true);
  const [mentors, setMentors] = useState<MentorSummary[]>([]);

  useEffect(() => {
    setCanSelectMentor(mentors.length > 0);
  }, [mentors]);

  const onSubmit = async (data: FormValues) => {
    const registerDto: RegisterDto = data;
    await register(registerDto)
      .unwrap()
      .then(() => {
        form.reset();
        setShowModal(true);
      })
      .catch((error) => {
        if (!isApiValidationError(error)) {
          notifications.show({
            message: "Сталася помилка, будь ласка, зв'яжіться з адміністратором платформи",
            title: 'Щось пішло не так',
            color: 'orange',
          });
          return;
        }

        const errorFields = error.data as { targetField: keyof FormValues; messages: string[] }[];
        errorFields.forEach(({ targetField, messages }) => {
          // Display only 1 message
          form.setError(targetField, { type: 'custom', message: messages[0] ?? '' });
        });
      });
  };

  const onModalClose = () => {
    setShowModal(false);
    navigate(NavigationRoutes.login);
  };

  const onStudyClassChange = (newValue: unknown) => {
    if (typeof newValue !== 'number') {
      return;
    }

    setMentors(filterMentorsByStudyClass(allMentors, newValue));
  };

  const canSubmitForm = formState.isValid;

  return (
    <>
      <FormProvider {...form}>
        <form className='main-form' onSubmit={form.handleSubmit(onSubmit)}>
          <FormGroup
            name={'fullName'}
            label={renderTranslation({ ru: 'Имя Фамилия', uk: 'Ім`я прізвище' })}
            placeholder={renderTranslation({
              ru: 'Введите ваши Имя и Фамилию',
              uk: "Введіть ваші Ім'я та Прізвище",
            })}
          />
          <FormGroup
            name={'email'}
            label={'Ваш email'}
            placeholder={renderTranslation({ ru: 'Введите email', uk: 'Введіть email' })}
            type={'email'}
          />
          <FormGroup
            name={'password'}
            label={'Пароль'}
            placeholder={renderTranslation({ ru: 'Введите пароль', uk: 'Введіть пароль' })}
            type={'password'}
            inputProps={{ maxLength: 18 }}
          />
          <SelectInput
            name={'studyClassNumber'}
            label={renderTranslation({ ru: 'Поток', uk: 'Потік' })}
            placeholder={renderTranslation({ ru: 'Выберите поток', uk: 'Виберіть потік' })}
            isLoading={studyClassLoading}
            options={mapStudyClassesToSelectArray(studyClasses, currentLanguage)}
            onChange={onStudyClassChange}
          />
          <SelectInput
            name={'mentorEmail'}
            label={'Ментор'}
            placeholder={renderTranslation({
              ru: 'Выберите наставника',
              uk: 'Виберіть наставника',
            })}
            isLoading={mentorsLoading}
            options={mapMentorsToSelectArray(mentors)}
            disabled={!canSelectMentor}
          />

          {isLoading ? (
            <Spinner />
          ) : (
            <button className='btn btn--green btn--lg' type='submit' disabled={!canSubmitForm}>
              {renderTranslation({ ru: 'Зарегистрироваться', uk: 'Зареєструватись' })}
            </button>
          )}
        </form>
      </FormProvider>
      <AlertModal
        onModalClose={onModalClose}
        onModalSubmit={onModalClose}
        controls={{ cancelBtn: false, submitBtn: true }}
        show={showModal}
        title={renderTranslation({ ru: 'Успех', uk: 'Успіх' })}>
        <p>
          {renderTranslation({
            ru: 'Вы сможете войти на платформу, когда',
            uk: 'Ви зможете увійти на платформу, коли',
          })}
          <a href='https://t.me/mentors_help_admin' target='_blank'>
            {renderTranslation({ ru: ' администратор ', uk: ' адміністратор ' })}{' '}
          </a>
          {renderTranslation({
            ru: 'подтвердит вашу регистрацию',
            uk: 'підтвердить вашу реєстрацію',
          })}
        </p>
      </AlertModal>
    </>
  );
};

const formDefaultValues: FormValues = {
  email: '',
  password: '',
  fullName: '',
  mentorEmail: '',
  studyClassNumber: 0,
};
