import { Route, Routes } from 'react-router-dom';
import { NavigationRoutes } from '../../shared';
import { CriteriaPage } from '../../pages/Criteria/criteria.page';
import { ProfilePage } from '../../pages/profile/profile.page';
import { StudentPage } from '../../pages/student-page/student.page';
import { ProjectsPage } from '../../pages/projects/projects.page';
import { AboutPage } from '../../pages/about/about.page';
import { CooperationPage } from '../../pages/cooperation/cooperation.page';
import { HomePage } from '../../pages/home/home.page';
import { TutorialsPage } from '../../pages/tutorials/tutorials.page';
import { ArticlesPage } from '../../pages/articles/articles.page';
import { ConsultPage } from '../../pages/consult/consult.page';
import { TasksPage } from '../../pages/tasks/tasks.page';
import { ErrorPage } from '../../pages/error/error.page';
import { DonatePage } from '../../pages/donate/donate.page';
import { AuthPage } from '../../pages/auth/auth.page';
import { LoggedInRedirect } from './redirects/logged-in-redirect';
import { RequireAuth } from './auth-protection/require-auth';
import { RequireInitData } from './guards/require-init-data';
import { PagesWrapper } from './pages-wrapper/pages-wrapper';

export const NavigationOutput = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route element={<RequireInitData />}>
          <Route element={<PagesWrapper />}>
            <Route path={NavigationRoutes.criteria} element={<CriteriaPage />} />
            <Route path={`${NavigationRoutes.home}*`} element={<HomePage />} />
            <Route path={`${NavigationRoutes.profile}`} element={<ProfilePage />} />
            <Route path={`${NavigationRoutes.studentPage}`} element={<StudentPage />} />
            <Route path={`${NavigationRoutes.projects}`} element={<ProjectsPage />} />
            <Route path={`${NavigationRoutes.about}`} element={<AboutPage />} />
            <Route path={`${NavigationRoutes.cooperation}`} element={<CooperationPage />} />
            <Route path={`${NavigationRoutes.tutorials}`} element={<TutorialsPage />} />
            <Route path={`${NavigationRoutes.articles}`} element={<ArticlesPage />} />
            <Route path={`${NavigationRoutes.consult}`} element={<ConsultPage />} />
            <Route path={`${NavigationRoutes.tasks}`} element={<TasksPage />} />
            <Route path={`${NavigationRoutes.error}`} element={<ErrorPage />} />
            <Route path={`${NavigationRoutes.donate}`} element={<DonatePage />} />
          </Route>
        </Route>
      </Route>

      <Route
        path={NavigationRoutes.login}
        element={
          <LoggedInRedirect>
            <AuthPage />
          </LoggedInRedirect>
        }
      />
      <Route
        path={NavigationRoutes.register}
        element={
          <LoggedInRedirect>
            <AuthPage />
          </LoggedInRedirect>
        }
      />
    </Routes>
  );
};
