import './social-list.scss';

import iconTg from '../../assets/icons/telegram.svg';
import iconTgAdmin from '../../assets/icons/telegram_admin.svg';
import iconYt from '../../assets/icons/youtube.svg';
// import iconPatreon from '../../assets/icons/patreon.svg';
import { useTranslation } from '../../hooks/useTranslation';

export const SocialList = (props: any) => {
  const { renderTranslation } = useTranslation();

  const className = `social ${props.newClass}`;

  const socials = {
    youtube: {
      icon: {
        WebkitMaskImage: `url(${iconYt})`,
        maskImage: `url(${iconYt})`,
      },
      title: renderTranslation({
        ru: 'Перейти на наш канал mentors-help в youtube',
        uk: 'Перейти на наш канал mentors-help в youtube',
      }),
      link: 'https://www.youtube.com/channel/UC-agFxxwGrsg96gMXiPqMNQ',
    },
    telegram: {
      icon: {
        WebkitMaskImage: `url(${iconTg})`,
        maskImage: `url(${iconTg})`,
      },
      title: renderTranslation({
        ru: 'Перейти на наш телеграм-канал',
        uk: 'Перейти на наш телеграм-канал',
      }),
      link: 'https://t.me/mentors_help',
    },
    telegramAdmin: {
      icon: {
        WebkitMaskImage: `url(${iconTgAdmin})`,
        maskImage: `url(${iconTgAdmin})`,
      },
      title: renderTranslation({ ru: 'Написать админу', uk: 'Написати адміну' }),
      link: 'https://t.me/mentors_help_admin',
    },
    // Temporary disable
    // patreon: {
    //   icon: {
    //     WebkitMaskImage: `url(${iconPatreon})`,
    //     maskImage: `url(${iconPatreon})`,
    //   },
    //   title: renderTranslation({ ru: 'Получить бонусы', uk: 'Отримати бонуси' }),
    //   link: 'https://www.patreon.com/mentors_help',
    // },
  };

  return (
    <ul className={className}>
      <li className='social__item'>
        <a
          href={socials.youtube.link}
          className='social__link'
          style={socials.youtube.icon}
          target='_blank'
          title={socials.youtube.title}>
          <span className='sr-only'> {socials.youtube.title} </span>
        </a>
      </li>
      <li className='social__item'>
        <a
          href={socials.telegram.link}
          className='social__link'
          style={socials.telegram.icon}
          target='_blank'
          title={socials.telegram.title}>
          <span className='sr-only'> {socials.telegram.title} </span>
        </a>
      </li>
      <li className='social__item'>
        <a
          href={socials.telegramAdmin.link}
          className='social__link'
          style={socials.telegramAdmin.icon}
          target='_blank'
          title={socials.telegramAdmin.title}>
          <span className='sr-only'> {socials.telegramAdmin.title} </span>
        </a>
      </li>
      {/* Temporary disable */}
      {/*<li className='social__item'>*/}
      {/*  <a*/}
      {/*    href={socials.patreon.link}*/}
      {/*    className='social__link'*/}
      {/*    style={socials.patreon.icon}*/}
      {/*    target='_blank'*/}
      {/*    title={socials.patreon.title}>*/}
      {/*    <span className='sr-only'> {socials.patreon.title} </span>*/}
      {/*  </a>*/}
      {/*</li>*/}
    </ul>
  );
};
