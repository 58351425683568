import { useTranslation } from '../../../hooks/useTranslation';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import {
  toggleShowDoneCriteria,
  toggleShowRelevantCriteria,
} from '../../../features/criteria/redux/criteria-slice';
import {
  showDoneCriteriaSelector,
  showRelevantCriteriaSelector,
} from '../../../features/criteria/redux/utils';
import { CriteriaSettingsCheckbox } from './checkbox/criteria-settings-checkbox';

import './criteria-settings.scss';

export const CriteriaSettings = () => {
  const showRelevantCriteria = useAppSelector(showRelevantCriteriaSelector);
  const showDoneCriteria = useAppSelector(showDoneCriteriaSelector);
  const dispatch = useAppDispatch();
  const { renderTranslation } = useTranslation();

  const onShowRelevantCriteriaChange = () => {
    dispatch(toggleShowRelevantCriteria());
  };

  const onShowDoneCriteriaChange = () => {
    dispatch(toggleShowDoneCriteria());
  };

  return (
    <div className='criteria-settings'>
      <CriteriaSettingsCheckbox
        id={'lesson-only-checkbox'}
        text={renderTranslation({
          ru: 'Показывать критерии только для текущего задания',
          uk: 'Показувати критерії тільки для поточного завдання',
        })}
        value={showRelevantCriteria}
        onChange={onShowRelevantCriteriaChange}
      />
      <CriteriaSettingsCheckbox
        id={'show-done-checkbox'}
        text={renderTranslation({
          ru: 'Показывать выполненные критерии',
          uk: 'Показувати виконані критерії',
        })}
        value={showDoneCriteria}
        onChange={onShowDoneCriteriaChange}
      />
    </div>
  );
};
