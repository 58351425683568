import { MentorSummary, StudyClassDto } from '../../openapi';
import { formatToDateString, Language, Languages } from '../../shared';

export function mapMentorsToSelectArray(mentors: MentorSummary[]) {
  return mentors.map((summary) => ({ value: summary.email, label: summary.fullName }));
}

export function filterMentorsByStudyClass(
  mentors: MentorSummary[],
  selectedStudyClassNumber: number,
): MentorSummary[] {
  return mentors.filter(
    (mentor) =>
      mentor.availableStudyClasses &&
      mentor.availableStudyClasses
        .map((studyClass) => studyClass.classNumber)
        .includes(selectedStudyClassNumber),
  );
}

export const selectStyles = {
  control: () => ({}),
  placeholder: () => ({}),
  valueContainer: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: () => ({}),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    color: 'rgba(var(--c-rgb-violet-200), 0.4)',
  }),
  input: () => ({}),
};

export function mapStudyClassesToSelectArray(studyClasses: StudyClassDto[], language: Language) {
  return [...studyClasses].reverse().map((studyClass) => {
    const translateLabel = (translation: string) =>
      `${translation} №${studyClass.classNumber}: ${formatToDateString(
        studyClass.startDate,
      )} - ${formatToDateString(studyClass.endDate)}`;

    return {
      value: studyClass.classNumber,
      label: translateLabel(language === Languages.uk ? 'Потік' : 'Поток'),
    };
  });
}
