import { z } from 'zod';

export type FormValues = {
  fullName: string;
  email: string;
  password: string;
  mentorEmail: string;
  studyClassNumber: number;
};

export const schema = z.object({
  fullName: z.string().min(1),
  email: z.string().email('Неправильний формат Email').min(1),
  password: z
    .string()
    .min(6, 'Мінімальна кількість символів - 6')
    .regex(/^[a-zA-Z0-9]+$/, 'Використання кирилиці не дозволяється'),
  mentorEmail: z.string().min(1),
  studyClassNumber: z.number().min(1),
});
