import { createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import { LoginDto, LoginResponseDto } from '../../../openapi';

const baseUrl = 'auth/';

const loginEndpoint: EndpointsSchema = {
  method: 'POST',
  path: createEndpoint(baseUrl),
};

export const loginApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseDto, LoginDto>({
      query: (body) => ({
        url: loginEndpoint.path,
        method: loginEndpoint.method,
        body,
      }),
    }),
  }),
});

export const { useLoginMutation } = loginApi;
