import { Link } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';
// import { useCurrentUserStudyClass } from '../../../hooks/useCurrentUserStudyClass';

import './menu.scss';

type Props = {
  classes: string;
};

export const Menu = ({ classes }: Props) => {
  const { renderTranslation } = useTranslation();
  // const { matchesCurrentStudyClass } = useCurrentUserStudyClass();

  return (
    <ul className={`menu ${classes}`}>
      <li className='menu__item'>
        <Link to='/about' className='menu__link'>
          {renderTranslation({ ru: 'О нас', uk: 'Про нас' })}
        </Link>
      </li>
      {/* Temporary disable */}
      {/*<li className='menu__item'>*/}
      {/*  <Link to='/donate' className='menu__link'>*/}
      {/*    {renderTranslation({ ru: 'Поддержать проект', uk: 'Підтримати проект' })}*/}
      {/*  </Link>*/}
      {/*</li>*/}
      {/*{!matchesCurrentStudyClass && (*/}
      {/*  <li className='menu__item'>*/}
      {/*    <Link to='/consult' className='menu__link'>*/}
      {/*      {renderTranslation({ ru: 'Заказать консультацию', uk: 'Замовити консультацію' })}*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*)}*/}
      {/*<li className='menu__item'>*/}
      {/*  <Link to='/cooperation' className='menu__link'>*/}
      {/*    {renderTranslation({ ru: 'Сотрудничество', uk: 'Співпраця' })}*/}
      {/*  </Link>*/}
      {/*</li>*/}
    </ul>
  );
};
