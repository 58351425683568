import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useAppSelector } from '../../hooks/useAppSelector';
import { isApiValidationError, NavigationRoutes, setAuthData } from '../../shared';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { logout } from '../user/redux/user-slice';
import { ApiErrorContent } from './modal-content/api-error-content';

import './modal.scss';

export const ApiErrorBoundaryFeature = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isError, message, type, payload } = useAppSelector((state) => state.app.apiErrorState);

  const isUnauthorizedError = message === 'Unauthorized';
  const isQueryError = !!type && type.includes('/executeQuery/');

  const reloadPage = () => {
    if (isUnauthorizedError) {
      // We are already at the login page here, so no redirect needed
      location.reload();
      return;
    }

    navigate(NavigationRoutes.login);
    location.reload();
  };

  useEffect(() => {
    if (isUnauthorizedError) {
      dispatch(logout());
      setAuthData({ token: '' });
    }
  }, [message]);

  useEffect(() => {
    if (!isError || isQueryError || !isApiValidationError(payload)) {
      return;
    }

    payload.data.forEach(({ messages }) => {
      messages.forEach((errorMsg) =>
        notifications.show({ message: errorMsg, title: 'Помилка', color: 'red' }),
      );
    });
  }, [isError, payload]);

  return (
    <Modal
      className={'api-error-modal'}
      size={'xl'}
      opened={isError && isQueryError}
      onClose={reloadPage}
      withCloseButton={false}
      centered>
      <div className='box'>
        <h1 className='main-title main-title--md main-title--indent alert__title'>Упс!</h1>
        <div className='alert__content'>
          <ApiErrorContent isUnauthorizedError={isUnauthorizedError} />
        </div>
      </div>
    </Modal>
  );
};
