import { createSelector } from '@reduxjs/toolkit';
import { ApiEntitiesTags, createEndpoint, EndpointsSchema } from '../../../shared';
import { appApi } from '../../app/app-api';
import {
  CriteriaProgressItemDto,
  MentorSummary,
  UpdateProfileDto,
  UserDto,
  UpdateUserProjectProgressBodyDto,
} from '../../../openapi';
import { ResetCriteriaProgressQueryParams, UpdateCriteriaProgressQueryParams } from './types';

const baseUrl = 'user/';

const getCurrentUserEndpoint: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'current'),
};

const getMentorsSummary: EndpointsSchema = {
  method: 'GET',
  path: createEndpoint(baseUrl + 'mentors-summary'),
};

const resetCriteriaProgress: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'progress/reset'),
};

const updateUserProfile: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'profile'),
};

const updateCriteriaProgress: EndpointsSchema = {
  method: 'PATCH',
  path: createEndpoint(baseUrl + 'progress'),
};

export const userApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserDto, void>({
      query: () => ({
        url: getCurrentUserEndpoint.path,
        method: getCurrentUserEndpoint.method,
      }),
      providesTags: [ApiEntitiesTags.user],
    }),
    getMentorsSummary: builder.query<MentorSummary[], void>({
      query: () => ({
        url: getMentorsSummary.path,
        method: getMentorsSummary.method,
      }),
    }),
    updateUserProfile: builder.mutation<UserDto, UpdateProfileDto>({
      query: (body) => ({ url: updateUserProfile.path, method: updateUserProfile.method, body }),
      async onQueryStarted(
        { gender, dateOfBirth, fullName, password, socialMedia },
        { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getCurrentUser', undefined, (currentUser) => {
            if (gender && currentUser.gender !== gender) {
              currentUser.gender = gender;
            }

            if (dateOfBirth && currentUser.dateOfBirth !== dateOfBirth) {
              currentUser.dateOfBirth = dateOfBirth;
            }

            if (fullName && currentUser.fullName !== fullName) {
              currentUser.fullName = fullName;
            }

            if (password && currentUser.password !== password) {
              currentUser.password = password;
            }

            if (socialMedia) {
              currentUser.socialMedia = socialMedia;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateCriteriaProgress: builder.mutation<
      CriteriaProgressItemDto,
      UpdateUserProjectProgressBodyDto & UpdateCriteriaProgressQueryParams
    >({
      query: (data) => {
        const { status, ...queryParams } = data;
        return {
          url: updateCriteriaProgress.path,
          method: updateCriteriaProgress.method,
          params: queryParams as UpdateCriteriaProgressQueryParams,
          body: { status } as UpdateUserProjectProgressBodyDto,
        };
      },
      async onQueryStarted({ projectId, status, criterionId }, { dispatch, queryFulfilled }) {
        try {
          const { data: progressItemDto } = await queryFulfilled;

          if (!progressItemDto) {
            return;
          }

          dispatch(
            userApi.util.updateQueryData('getCurrentUser', undefined, (currentUser) => {
              const updatedProject = currentUser.projects.find(
                (project) => project.projectId === projectId,
              );

              if (!updatedProject) {
                return;
              }

              const updatedCriterionProgress = updatedProject.criteriaProgress.find(
                (progress) => progress.criterionId === criterionId,
              );

              if (updatedCriterionProgress && updatedCriterionProgress.status !== status) {
                updatedCriterionProgress.status = status;
              } else {
                updatedProject.criteriaProgress.push(progressItemDto);
              }
            }),
          );
        } catch {}
      },
    }),
    resetCriteriaProgress: builder.mutation<UserDto, ResetCriteriaProgressQueryParams>({
      query: (queryParams) => ({
        url: resetCriteriaProgress.path,
        method: resetCriteriaProgress.method,
        params: queryParams,
      }),
      async onQueryStarted({ projectId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          userApi.util.updateQueryData('getCurrentUser', undefined, (currentUser) => {
            const projectToReset = currentUser.projects.find(
              (userProject) => userProject.projectId === projectId,
            );

            if (projectToReset) {
              projectToReset.criteriaProgress = [];
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetMentorsSummaryQuery,
  useUpdateUserProfileMutation,
  useUpdateCriteriaProgressMutation,
  useResetCriteriaProgressMutation,
} = userApi;

export const currentUserSelector = createSelector(
  userApi.endpoints.getCurrentUser.select(),
  (result) => result.data ?? null,
);
