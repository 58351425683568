import { ArticleCard } from '../../components/article-card/article-card';
import imageInstructions from '../../assets/content-covers/other/instructions.png';
import imageSecrets from '../../assets/content-covers/other/secrets.jpg';
import imageAdd from '../../assets/content-covers/other/add.jpg';
import desBal from '../../assets/content-covers/design/des_ball.jpg';
import desPorto from '../../assets/content-covers/design/des_porto.jpg';
import desMark from '../../assets/content-covers/design/des_mark.jpg';
import desWawe from '../../assets/content-covers/design/des_wawe.jpg';
import desSF from '../../assets/content-covers/design/des_sf.jpg';
import desFresh from '../../assets/content-covers/design/des_fresh.jpg';
import desMarcho from '../../assets/content-covers/design/des_marcho.jpg';
import desGlee from '../../assets/content-covers/design/des_glee.jpg';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { useStudyClasses } from '../../hooks/useStudyClasses';
import { formatDate, getDaysDiff } from '../../shared';
import { useTranslation } from '../../hooks/useTranslation';
import { useCurrentUserStudyClass } from '../../hooks/useCurrentUserStudyClass';
import { useCurrentUser } from '../../hooks/redux/useCurrentUser';

import './student.page.scss';

export const StudentPage = () => {
  const { renderTranslation } = useTranslation();
  useDocumentTitle(renderTranslation({ ru: `Страница студента`, uk: `Сторінка студента` }));

  const { currentStudyClass } = useStudyClasses();
  const { matchesCurrentStudyClass: doesUserIsInCurrentStudyClass } = useCurrentUserStudyClass();
  const { currentUser } = useCurrentUser();
  // const dates = {
  //null
  //     first: 22, /* friday 22-07 */ 3 hw
  //     second: 25, /* monday 25-07 */ 4 hw
  //     third: 6,  /* monday 06-08 */ 12 hw
  // }

  // const pageData = {

  //     firstPart: {
  //         /*макет ballanties */
  //     },

  //     secondPart: {
  //         /*макети portfolio, wawe, o marketing */
  //     },

  //     thirdPart: {
  //         /*макети sf, freshok, glee, ? */
  //     }
  // }

  // TODO: rework
  const showSecondBlock = doesUserIsInCurrentStudyClass
    ? !!(currentStudyClass && getDaysDiff(new Date(currentStudyClass.startDate)) >= 5)
    : true;

  const showThirdBlock = doesUserIsInCurrentStudyClass
    ? !!(currentStudyClass && getDaysDiff(new Date(currentStudyClass.startDate)) >= 7)
    : true;

  const showFourthBlock = doesUserIsInCurrentStudyClass
    ? !!(currentStudyClass && getDaysDiff(new Date(currentStudyClass.startDate)) >= 19)
    : true;

  return (
    <main className='page student-page'>
      <div className='container'>
        <section className='box'>
          <div className='page__head'>
            <h1 className='main-title main-title--indent'>
              {renderTranslation({ ru: `Страница студента`, uk: `Сторінка студента` })}
            </h1>
            <p className='main-title main-title--md'>
              {renderTranslation({
                ru: `Здесь собраны ссылки на материалы курса для студентов`,
                uk: `Тут зібрані посилання на матеріали курсу для студентів`,
              })}{' '}
              {currentUser &&
                currentUser.studyClass &&
                `${currentUser.studyClass.classNumber} ${renderTranslation({
                  ru: 'потока',
                  uk: 'потоку',
                })} (${formatDate(new Date(currentUser.studyClass?.startDate))} - ${formatDate(
                  new Date(currentUser.studyClass?.endDate),
                )})`}
            </p>
          </div>

          <div className='student-content null'>
            <ul className='student-content__list d-flex'>
              <li className='student-content__item'>
                <ArticleCard
                  cardImage={imageInstructions}
                  cardLabel='instruction'
                  cardLink='notion://www.notion.so/8b6e31cd0b5540b2ade153d11da0da9a'
                  cardName={renderTranslation({
                    ru: `Инструктаж курса от 0до1`,
                    uk: `Інструктаж курсу від 0до1`,
                  })}
                />
              </li>
              <li className='student-content__item'>
                <ArticleCard
                  cardImage={imageSecrets}
                  cardLabel='secrets'
                  cardLink='notion://www.notion.so/040086595edf496389991950f580e0d5'
                  cardName={renderTranslation({
                    ru: `Секреты проходжения курса`,
                    uk: `Секрети проходження курсу`,
                  })}
                />
              </li>

              <li className='student-content__item'>
                <ArticleCard
                  cardImage={imageAdd}
                  cardLabel='task'
                  cardLink='https://www.notion.so/f0t1/4cfdb8189c2a47488110d674d725c6c3'
                  cardName={renderTranslation({
                    ru: `Дополнительные задания`,
                    uk: `Додаткові завдання`,
                  })}
                />
              </li>
            </ul>
          </div>

          {showSecondBlock && (
            <div className='student-content firts'>
              <ul className='student-content__list d-flex'>
                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desBal}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/Fq02VAMULGpkYfxP2tCOCC/3%D0%94%D0%97?node-id=1%3A3'
                    cardName='Макет "Ballanties"'
                    designType='figma'
                    cardLevel='light'
                  />
                </li>
              </ul>
            </div>
          )}

          {showThirdBlock && (
            <div className='student-content second'>
              <ul className='student-content__list d-flex'>
                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desPorto}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/I5Rmg5Csy6YDj767YadL04/%D0%9F%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D1%96%D0%BE-(%D1%83%D0%BA%D1%80)?node-id=0%3A1'
                    cardName='Макет "Portfolio"(укр)'
                    designType='figma'
                    cardLevel='normal'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desPorto}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/B4NAPvt9O67Ltrcw8zFHYJ/Portfolio?node-id=0%3A1'
                    cardName='Макет "Portfolio"(рус)'
                    designType='figma'
                    cardLevel='normal'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desMark}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/cMqlQV7d2ZawwZqpTZXlb7/O...MARKETING-f0t1?node-id=0%3A1'
                    cardName='Макет "Marketing"'
                    designType='figma'
                    cardLevel='normal'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desWawe}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/nDuQvrpb6z6TWSoDve8Ym7/WAWE-f0t1?node-id=0%3A1'
                    cardName='Макет "Wawe"'
                    designType='figma'
                    cardLevel='normal'
                  />
                </li>
              </ul>
            </div>
          )}

          {showFourthBlock && (
            <div className='student-content third'>
              <ul className='student-content__list d-flex'>
                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desSF}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/X1iUij9NQpODLgLI0GzmjG/Simple-Food-(%D1%80%D1%83%D1%81)?node-id=0%3A1'
                    cardName='Макет "SimpleFood"(рус)'
                    designType='figma'
                    cardLevel='hard'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desFresh}
                    cardLabel='design'
                    cardLink='https://www.figma.com/file/MYcj0hKFl3V32Z1bxNCSrb/%D0%A4%D1%80%D0%B5%D1%88%D0%9E%D0%BA-(%D1%80%D1%83%D1%81)?node-id=0%3A1'
                    cardName='Макет "ФрешОк"(рус)'
                    designType='figma'
                    cardLevel='hard'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desMarcho}
                    cardLabel='design'
                    cardLink='https://drive.google.com/file/d/1Ipw-dJ6h7RgBYTt2t85MM78s36Zr4QrH/view?usp=sharing'
                    cardName='Макет "Marcho"'
                    designType='ps'
                    cardLevel='hard'
                  />
                </li>

                <li className='student-content__item'>
                  <ArticleCard
                    cardImage={desGlee}
                    cardLabel='design'
                    cardLink='https://drive.google.com/file/d/1TI6WlIKHTWR1T0WOqPsUFTJmxsU3_VwK/view'
                    cardName='Макет "Glee"'
                    designType='ps'
                    cardLevel='hard'
                  />
                </li>
              </ul>
            </div>
          )}
        </section>
      </div>
    </main>
  );
};
