import { z } from 'zod';

export const ZApiValidationError = z.object({
  status: z.number(),
  data: z.array(
    z.object({
      targetField: z.string(),
      messages: z.array(z.string()),
    }),
  ),
});

export type ApiValidationError = z.infer<typeof ZApiValidationError>;

export function isApiValidationError(error: unknown): error is ApiValidationError {
  return ZApiValidationError.safeParse(error).success;
}

export const ZUnauthorizedResponse = z.object({
  status: z.number().refine((num) => num === 401),
  data: z.object({
    statusCode: z.number().refine((num) => num === 401),
    message: z.string().refine((str) => str === 'Unauthorized'),
  }),
});

export type UnauthorizedResponse = z.infer<typeof ZUnauthorizedResponse>;

export function isUnauthorizedResponse(error: unknown): error is UnauthorizedResponse {
  return ZUnauthorizedResponse.safeParse(error).success;
}
