import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';
import React from 'react';
import { FormValues } from '../types';
import { selectStyles } from '../helpers';

type Props = {
  name: keyof FormValues;
  label: string;
  placeholder: string;
  isLoading: boolean;
  disabled?: boolean;
  options: { value: any; label: string }[];
  onChange?: (value: unknown) => void;
};

export const SelectInput = ({
  name,
  options,
  label,
  disabled = false,
  placeholder,
  isLoading,
  onChange: onChangeProp,
}: Props) => {
  const { control, formState, clearErrors } = useFormContext<FormValues>();

  return (
    <Controller
      render={({ field: { onChange, onBlur } }) => (
        <div
          className={`main-form__group ${formState.errors[name] ? 'main-form__group--error' : ''}`}>
          <label className='main-form__label' htmlFor='yourMentor'>
            {label}
          </label>
          <Select
            id={name}
            name={name}
            className='main-form__select basic-single'
            classNamePrefix='select'
            options={options}
            styles={selectStyles}
            placeholder={placeholder}
            isLoading={isLoading}
            onChange={(event) => {
              onChange(event!.value);
              if (onChangeProp) onChangeProp(event!.value);
            }}
            isDisabled={disabled}
            onFocus={() => {
              onBlur();
              clearErrors(name);
            }}
          />
        </div>
      )}
      name={name}
      control={control}
    />
  );
};
